import styled from "styled-components";
import { ImageMeta } from "../models/ImageExpandContext";

const ImageCarousel = (props: ImageCarouselProps) => {
  return (
    <>
      <CarouselStyled className="carousel" $currentIndex={props.currentIndex} $carouselImageCount={props.carouselImages.length}>
        {props.carouselImages.map(x => {
          return (
            <CarouselSlideStyled className="slide" onClick={props.onCloseClick}>
              <CarouselSlideImageStyled src={x.url} draggable="false" />
            </CarouselSlideStyled>);
        })}
      </CarouselStyled>
      {props.carouselImages.length > 1 && <ImageExpandNavigationStyled $currentIndex={props.currentIndex}>
        {props.carouselImages.map((_, index) => <ImageExpandNavigationElementStyled data-index={index} onClick={() => props.onNavigationElementClick(index)} />)}
      </ImageExpandNavigationStyled>}
    </>
  )
}

export default ImageCarousel

interface ImageCarouselProps {
  currentIndex: number,
  carouselImages: ImageMeta[],
  onCloseClick: () => void,
  onNavigationElementClick: (index: number) => void
}

const ImageExpandNavigationElementStyled = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid var(--element-color);
  border-radius: 0.5rem;
  
  &:hover {
    background-color: var(--element-color);
  }
`

const ImageExpandNavigationStyled = styled.nav<ImageExpandNavigationStyledProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  z-index: 13;

  & div[data-index="${props => props.$currentIndex}"] {
    background-color: var(--element-color);
  }
`

interface ImageExpandNavigationStyledProps {
  $currentIndex: number;
}


const CarouselStyled = styled.div<CarouselStyledProps>`
  z-index: 11;
  display: flex;
  width: ${props => props.$carouselImageCount}00%;
  height: 100%;
  position: absolute;
  left: 0;
  --current-translate: -${props => props.$currentIndex}00%;
`

interface CarouselStyledProps {
  $currentIndex: number,
  $carouselImageCount: number
}

const CarouselSlideStyled = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
  transform: translateX(var(--current-translate));
  transition: all 250ms ease-out 0s;
`

const CarouselSlideImageStyled = styled.img<CarouselSlideImageStyledProps>`
  margin: auto;
  user-select:none;
  max-width: 800px;
  max-height: 800px;
`

interface CarouselSlideImageStyledProps {
}