export interface Message {
  id?: number,
  side: MessageSide,
  payload: MessagePart[],
  date?: string
}

export interface MessagePart {
  type: MessagePartType,
  data: string
  time: Date
}

export enum MessagePartType {
  Text = 1,
  Image = 2
}

export enum MessageSide {
  Client = 1,
  User = 2,
  Bot = 3
}

export interface ImageMessagePart extends MessagePart {
  aspectRatio: number,
  width: number,
  height: number
}
