import { forwardRef } from "react"
import styled from "styled-components"
import ConnectionState from "../models/ConnectionState"
import ControlsWrapper from "./ControlsWrapper"

let Footer = forwardRef<HTMLDivElement, FooterProps>((props: FooterProps, ref) => {
  return (
    <FooterStyled ref={ref}>
      <ControlsWrapper onMessageSent={props.onMessageSent} disabled={props.disabled} />
    </FooterStyled>
  )
})

interface FooterProps {
  onMessageSent: (msg: string) => void
  disabled: boolean
}

let FooterStyled = styled.div`
  max-height: 144px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 8px 40px 8px;
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%);
  box-sizing: border-box;
  flex-grow: 0;
  position: sticky;
  bottom: 0;
  //max-width: 784px;

  padding: 21px 16px;
  gap: 8px;
  min-height: 62px;
  background: #FFFFFF;
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  border-color: #F4F4F4;
`

export default Footer