import React from "react";
import { Message, MessageSide } from "./Message";

export class MessagesContextType {
    public messages: Message[] = []

    addMessage: (msg: Message, callback: {() : void} | undefined) => void = (msg, callback) => {}
    addMessages: (msgs: Message[], callback: {() : void} | undefined) => void = (msg, callback) => {}

    clearMessages: (callback: {() : void} | undefined) => void = (callback) => {}

    constructor(messages: Message[]) {
        this.messages = messages;
    }
}

const MessagesContext = React.createContext<MessagesContextType>(new MessagesContextType([]));

export default MessagesContext;