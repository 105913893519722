import { MessagePartType } from "./Message"

export interface MessageDto {
    id?: number
    interviewUid?: string
    text: string
    type: MessagePartType
    role?: ChatRole
    status?: ChatMessageStatus
    date?: Date
}

export enum ChatRole {
    Client = 1,
    User = 2,
    Bot = 3
}

export enum ChatMessageStatus {
    Live = 1,
    Edit = 2,
    Delete = 3
}