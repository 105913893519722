import React, { Component, PropsWithChildren, useState } from "react";
import { Message } from "../models/Message";
import MessagesContext, { MessagesContextType } from "../models/MessagesContext";

class MessagesContextProvider extends Component<PropsWithChildren<MessagesContextProviderProps>, MessagesContextProviderState> {

  //this.state = { messages: [...this.props.messages]}
  constructor(props: PropsWithChildren<MessagesContextProviderProps>) {
    super(props);
    this.state = { messages: [...this.props.messages] };
  }

  addMessage = (newMessage: Message, callback: { (): void } | undefined) => {
    if (newMessage.id === undefined) {
      let newId = this.state.messages.at(-1)?.id ?? 0;
      newMessage.id = newId + 1;
    }
    this.setState({ messages: [...this.state.messages, newMessage] }, callback)
  };

  addMessages = (newMessages: Message[], callback: { (): void } | undefined) => {
    let messages = this.state.messages.map(x => {
      let editMessage = newMessages.find(msg => msg.id == x.id);
      if (editMessage) {
        newMessages = newMessages.filter(msg => msg.id != x.id);
        return editMessage;
      }
      return x;
    })
    this.setState({ messages: [...messages, ...newMessages] }, callback)
  }

  clearMessages = (callback: { (): void } | undefined) => {
    this.setState({ messages: [] }, callback);
  }

  render() {
    return (
      <MessagesContext.Provider value={{
        messages: this.state.messages,
        addMessage: this.addMessage,
        addMessages: this.addMessages,
        clearMessages: this.clearMessages
      }}>
        {this.props.children}
      </MessagesContext.Provider>
    )
  }
}

export default MessagesContextProvider

interface MessagesContextProviderState {
  messages: Message[]
}

interface MessagesContextProviderProps {
  messages: Message[],
}