import styled, { keyframes } from "styled-components"
import { MessagePartType, ImageMessagePart, Message, MessageSide } from "../models/Message"
import { ImageMeta } from "../models/ImageExpandContext"
import ImageGallary from "./ImageGallery"

let MessageBubble = (props: TextBubbleProps) => {
  let imageMetas: ImageMeta[] = props.message.payload.filter(x => x.type === MessagePartType.Image).map((x) => {
    const imageMessagePart = x as ImageMessagePart;
    return {
      url: x.data,
      aspectRatio: imageMessagePart.aspectRatio,
      width: imageMessagePart.width,
      height: imageMessagePart.height
    }
  })
  let time = new Date(props.message.payload.map(x => x.time)[0])
  return (
    <>
      <TextBubbleStyled className="msgbubble" side={props.message.side} temp={props.temp}>
        {imageMetas.length > 0 && <ImageGallary imageMetas={imageMetas} />}
        {imageMetas.length === 0 && <>{props.message.payload.map(x => x.data).join('\n')}</>}
      </TextBubbleStyled>
      <TimeTextStyled>{time.getHours().toString().padStart(2, '0')}:{time.getMinutes().toString().padStart(2, '0')}</TimeTextStyled>
    </>
  )
}

const userTextColor = '#000000'
const botColor = '#a5a5a5'
const botTextColor = '#000000'
const botTypeingPlaceholderTextColor = 'rgb(44, 44, 46, 0.6)';

const typingAnimation = keyframes`
  0% { color: rgb(44, 44, 46, 0.6) }
  50% { color: rgb(44, 44, 46, 0.9) }
  100% { color: rgb(44, 44, 46, 0.6) }
 `

export let TextBubbleStyled = styled.div<TextBubbleStyledProps>`
  white-space: pre-wrap;
  background: ${props => props.side === MessageSide.User ? '#FFFFFF' : 'linear-gradient(0deg, rgba(21, 164, 208, 0.1), rgba(21, 164, 208, 0.1)), #FFFFFF'};
  color: ${props => props.side === MessageSide.User ? (props.temp ? botTypeingPlaceholderTextColor : botTextColor) : (props.side === MessageSide.Client ? userTextColor : botColor)};
  //padding: 8px 16px 12px 16px;
  //border-radius: ${props => props.side === MessageSide.User ? '8px 8px 8px 0px' : '8px 8px 0px 8px'};
  border-radius: 8px;
  word-break: break-word;
  text-align: left;
  max-width: 400px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  padding-right: 43px;
  gap: ${props => props.side === MessageSide.User ? '4px' : '8px'};
  isolation: isolate;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 1;
  z-index: 0;

  animation-name: ${props => props.temp ? typingAnimation : ""};
  animation-duration: 1600ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &.sticky-date {
    margin: 0 auto 0.125rem;
    align-self: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    color: #fff;
    font-weight: 600;
    line-height: 1.75;
    padding: 0 0.5rem;
    word-break: break-word;
    pointer-events: none;
    border-radius: 12px;
    background: rgba(0,0,0,.3);
    cursor: default;
    max-height: 32px;
  }
`

let TimeTextStyled = styled.div`
  position: absolute;
  right: 8px;
  bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: #000000;
  opacity: 0.3;
`

interface TextBubbleStyledProps {
  side: MessageSide
  temp?: boolean
}

interface TextBubbleProps {
  message: Message
  temp?: boolean
}

export default MessageBubble