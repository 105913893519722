import { forwardRef } from "react"
import styled from "styled-components"

let Header = forwardRef<HTMLDivElement, HeaderProps>((props: HeaderProps) => {
  return (
    <HeaderStyled>
      {props.nameResp}
    </HeaderStyled>
  )
})

interface HeaderProps {
  nameResp: string
}

let HeaderStyled = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 8px 40px 8px;
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%);
  box-sizing: border-box;
  flex-grow: 1;
  align-self: center;
  position: sticky;
  top: 0;
  //max-width: 784px;

  padding: 21px 16px;
  gap: 2px;
  height: 62px;
  background: #FFFFFF;
  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #F4F4F4;

  flex: none;
  order: 0;
  align-self: stretch;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.3px;

  z-index: 3;
`

export default Header