import React, { Component, PropsWithChildren, useState } from "react";

class InterviewContextProvider extends Component<PropsWithChildren<MessagesContextProviderProps>, InterviewContextProviderState> {

    constructor(props: PropsWithChildren<MessagesContextProviderProps>) {
        super(props);
        this.state = { status: 0 };
    }

    // setInterviewStatus = (newStatus: number) => {
    //     this.setState({ status: newStatus })
    // };

    render() {
        return (
            <InterviewContext.Provider value={{
                status: this.state.status,
                setInterviewStatus: this.props.setInterviewStatus
            }}>
                {this.props.children}
            </InterviewContext.Provider>
        )
    }
}

export default InterviewContextProvider

interface InterviewContextProviderState {
    status: number
}

interface MessagesContextProviderProps {
    setInterviewStatus: (status: number) => void;
}

class InterviewContextType {
    public status: number = 0

    setInterviewStatus: (status: number) => void = (status) => { }
}

export const InterviewContext = React.createContext<InterviewContextType>(new InterviewContextType());