import React, { createRef, KeyboardEvent, MouseEvent, FormEvent } from "react";
import styled from "styled-components";
import ConnectionState from "../models/ConnectionState";
import { MessagePartType, MessageSide } from "../models/Message";
import MessagesContext from "../models/MessagesContext";

class ControlsWrapper extends React.Component<ControlsWrapperProps, { inputValue: string }> {

    static contextType?: React.Context<any> | undefined = MessagesContext;
    context!: React.ContextType<typeof MessagesContext>

    state: Readonly<{ inputValue: string }> = {
        inputValue: ""
    }

    inputRef = createRef<HTMLTextAreaElement>();
    inputWrapperRef = createRef<HTMLDivElement>();

    componentDidMount() {
        this.setInputHeight();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{ inputValue: string }>, snapshot?: any): void {
        if (prevState.inputValue !== this.state.inputValue)
            this.setInputHeight();
    }

    onInput: (e: FormEvent<HTMLTextAreaElement>) => void = (e) => {
        this.setInputHeight();
        this.setState({ inputValue: e.currentTarget.value })
    }

    setInputHeight: () => void = () => {
        let textarea = this.inputRef.current!;
        textarea.style.height = "0";
        textarea.style.height = (textarea.scrollHeight) + "px";

        let textareaWrapper = this.inputWrapperRef.current!;
        let taLineHeight = Number.parseInt(window.getComputedStyle(textarea).getPropertyValue('line-height'));
        let taHeight = textarea.scrollHeight;
        let numberOfLines = Math.floor(taHeight / taLineHeight);
        //textareaWrapper.style.height = ((numberOfLines + 1) * taLineHeight) + 'px';
    }

    keyDownFilter: (e: KeyboardEvent<HTMLTextAreaElement>) => void = (e) => {
        if (e.code == "Enter" && !e.ctrlKey) {
            e.preventDefault();
            this.sendMessage();
        }
        if (e.code == "Enter" && (e.ctrlKey || e.shiftKey)) {
            this.setState({ inputValue: this.state.inputValue + '\r\n' }, () => {
                this.inputWrapperRef.current?.scrollTo(0, this.inputWrapperRef.current.scrollHeight * 2);
            })
        }
    }

    sendMessageByClick: (e: MouseEvent<HTMLDivElement>) => void = (e) => {
        this.sendMessage();
    }

    sendMessage: () => void = () => {
        if (!(this.state.inputValue.length > 0) || this.props.disabled)
            return;
        this.context.addMessage({
            id: this.context.messages.at(-1) ? this.context.messages.at(-1)?.id! + 1 : 0,
            side: MessageSide.Client,
            payload: [{
                type: MessagePartType.Text,
                data: this.state.inputValue,
                time: new Date()
            }]
        }, () => {
            this.props.onMessageSent(this.state.inputValue);
            this.setState({ inputValue: "" })
        });
    }

    render() {
        return (
            <ControlsWrapperStyled className={`${this.props.disabled? 'disabled':''}`}>
                <InputWrapperStyled ref={this.inputWrapperRef}>
                    <InputStyled ref={this.inputRef} onInput={this.onInput} value={this.state.inputValue} onKeyDown={this.keyDownFilter} /*onKeyDown={sendMessageByEnter}*/ placeholder="Наберите сообщение…" />
                </InputWrapperStyled>
                <SendButtonStyled onClick={this.sendMessageByClick}>
                    <SvgStyled width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.8296 11.693L2.22414 1.86328C2.14445 1.82344 2.05304 1.81406 1.96632 1.83516C1.7671 1.88437 1.64289 2.08594 1.6921 2.2875L3.71242 10.5422C3.74289 10.6664 3.83429 10.7672 3.95617 10.807L7.41789 11.9953L3.95851 13.1836C3.83664 13.2258 3.74523 13.3242 3.71711 13.4484L1.6921 21.7148C1.67101 21.8016 1.68039 21.893 1.72023 21.9703C1.81164 22.1555 2.03664 22.2305 2.22414 22.1391L21.8296 12.3656C21.9023 12.3305 21.9609 12.2695 21.9984 12.1992C22.0898 12.0117 22.0148 11.7867 21.8296 11.693ZM4.00304 19.3664L5.18195 14.5477L12.1007 12.1734C12.1546 12.1547 12.1991 12.1125 12.2179 12.0563C12.2507 11.9578 12.1991 11.8523 12.1007 11.8172L5.18195 9.44531L4.00773 4.64531L18.7265 12.0258L4.00304 19.3664V19.3664Z" fill="#979797" />
                    </SvgStyled>
                </SendButtonStyled>
            </ControlsWrapperStyled>
        )
    }
}

export default ControlsWrapper

interface ControlsWrapperProps {
    onMessageSent: (msg: string) => void
    disabled: boolean
}

let ControlsWrapperStyled = styled.div`
width: 100%;
display: flex;
//position: relative;
align-items: center;
flex: 1;

&.disabled {
    opacity: 0.4;
    div, textarea {
        cursor: not-allowed;
        pointer-events: none;
    }
}
`

let SvgStyled = styled.svg`
//opacity: 0.3;
&:hover {
    path {
        fill: #717171;
    }
}
`

let SendButtonStyled = styled.div`
  display: flex;
  position: absolute;
  right: 21px;
  align-items: center;
  `

let InputWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  --align-items: center;
  color: rgb(44, 44, 46);
  resize: none;
  outline: none;
  overflow: auto;
  flex: 1;

  margin-right: 27px;
  
  scrollbar-width: thin;
  scrollbar-color: #249de4 #f5f5f5;

  background: white;
`

let InputStyled = styled.textarea`
  width: 100%;
  max-height: 144px;
  //padding: 4px 8px 4px 16px;
  //margin: 4px 40px 4px 0px;
  margin: 0;
  padding: 0;
  border: none;
  resize: none;
  outline: none;

  display: flex;
  align-self: flex-start;

  font-family: 'Proxima Nova', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;

  font-family: 'Proxima Nova';
  font-weight: 400;
  line-height: 20px;
  align-items: center;

  color: #000000;

  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }

  &::-webkit-scrollbar-button, &::-webkit-scrollbar-track{
      height: 10px;
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: transparent;
  }
  
  &::placeholder {
    //color: #2C2C2E;
    opacity: 0.6;
  }
`