import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ImageExpandContext, { ImageMeta } from "../models/ImageExpandContext";
import { MessageSide } from "../models/Message";
import ImageBase from "./ImageBase";

interface ImageGalleryProps {
  imageMetas: ImageMeta[]
}

const ImageGallary = (props: ImageGalleryProps) => {
  const [imageGroups, setImageGroups] = useState<ImageMeta[][]>([])
  const [imagesRowWidth, setImagesRowWidth] = useState<number>(0)
  const [rerenderUid, setRerenderUid] = useState<string>("")
  const gallaryRef = useRef<HTMLDivElement>(null)
  const gallaryWrapperRef = useRef<HTMLDivElement>(null)

  const imageExpandContext = useContext(ImageExpandContext);

  const resizeHandler = () => {
    setRerenderUid(crypto.randomUUID());
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    if (imageGroups.length === 0) {
      let groups: ImageMeta[][] = []

      if (props.imageMetas.length === 1) {
        groups.push(props.imageMetas)
      }
      else if (props.imageMetas.length % 2 === 0) {
        for (let i = 0; i < props.imageMetas.length / 2; i++) {
          groups.push(props.imageMetas.slice(i * 2, 2 + i * 2));
        }
      }
      else {
        let maximizeFirstImage: boolean = false;
        const firstElement = props.imageMetas[0];
        const lastElement = props.imageMetas.at(-1)!;

        let index: number = 0;
        if (maximizeFirstImage) {
          groups[0] = props.imageMetas.slice(index * 2, index * 2 + 1);
          index = 1;
        }
        for (index; index < Math.ceil(props.imageMetas.length / 2); index++) {
          let indexStart = maximizeFirstImage ? index * 2 - 1 : index * 2;
          let group: ImageMeta[] = props.imageMetas.slice(indexStart, indexStart + 2);
          groups.push(group);
        }
      }

      let maxImageRowWidth = 0

      setImageGroups(groups);
      setImagesRowWidth(maxImageRowWidth);
    }

    return () => {
      window.removeEventListener("resize", resizeHandler);
    }
  },
    [])

  const imageRows = imageGroups.map((group, index) => {

    const onClick = (src: string) => {
      imageExpandContext.showImageExpand(src, props.imageMetas);
    }
    return (<>{group.map((x, gIndex) => {
      return (<ImageWrapperStyled key={gIndex} onClick={() => onClick(x.url)}>
        <ImageBase src={x.url} sizes={{ w: 0, h: 0 }} />
      </ImageWrapperStyled>)
    })}</>)
  })

  return (
    <MessageImageGalleryStyled ref={gallaryRef} className="gallery">
      <ImageGalleryFitContentWrapperStyled ref={gallaryWrapperRef} className="gallery-wrapper">
        {imageRows}
      </ImageGalleryFitContentWrapperStyled>
    </MessageImageGalleryStyled>
  )
}

export default ImageGallary

const MessageImageGalleryStyled = styled.div`
  display: flex;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  flex-direction: column;
  max-width: 350px;
  //max-height: 280px;
`

const MessageTextStyled = styled.span<MessageTextStyledProps>`
  background-color: var(--background-color);
  padding: 8px 16px 12px 16px;
  box-sizing: border-box;
`

interface MessageTextStyledProps {
  widthParam: number | undefined,
  side: MessageSide,
  last: boolean
}

const ImageWrapperStyled = styled.div<ImageWrapperStyledProp>`
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 125px;
  height: 160px;
  max-width: 160px;
`

interface ImageWrapperStyledProp {
}

interface ImageWrapperStyledBorderRadius {
  topLeft: boolean,
  topRight: boolean,
  bottomLeft: boolean,
  bottomRight: boolean
}

const ImageGalleryFitContentWrapperStyled = styled.div<ImageGalleryFitContentWrapperStyledProps>`
   display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  flex-direction: row;
  max-width: 350px;
  align-content: flex-start;
  justify-content: space-between;
  justify-content: center;
  flex-direction: row;
  align-content: flex-start;
`

interface ImageGalleryFitContentWrapperStyledProps {
}

const ImageGridRowStyled = styled.div<ImageGridRowStyledProp>`
  display: flex;
`
interface ImageGridRowStyledProp {
}
