import { forwardRef, Fragment, RefObject, useContext, useRef, useEffect } from "react"
import styled from "styled-components"
import { Message, MessagePartType, MessageSide } from "../models/Message"
import MessagesContext, { MessagesContextType } from "../models/MessagesContext"
import MessageBlock from "./MessageBlock"
import { TextBubbleStyled } from "./MessageBubble"
import React from "react"

let MessageWrapper = forwardRef<HTMLDivElement>((_, ref) => {

  const consumer = useContext(MessagesContext);
  const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
  const consumerWithData = consumer.messages.map(x => {
    let time = new Date(x.payload.map(x => x.time)[0]);
    x.date = time.getDate().toString() + " " + months[time.getMonth()]
    return x;
  });
  var groupBy = function (xs: any[], key: string) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const consumerGroupByTime = groupBy(consumerWithData, 'date');

  let messagesContainerRef = useRef<HTMLDivElement>(null);
  let observer = useRef<ResizeObserver>();
  useEffect(() => {
    observer.current = new ResizeObserver(() => {
      let r: any = ref;
      r!.current!.scrollTo(0, r!.current.scrollHeight * 2)
    });
    observer.current.observe(messagesContainerRef.current!);
  }, []);

  return (
    <MessageBaseContainerStyled>
      <MessageWrapperStyled ref={ref} className='message-wrapper'>
        <MessagesScrolledContainerStyled ref={messagesContainerRef}>
          {Object.keys(consumerGroupByTime).map(function (key, index) {
            let messages = consumerGroupByTime[key];
            let arr: any[] = [];
            arr.push(<TextBubbleStyled className="msgbubble sticky-date" side={MessageSide.Bot}>{key}</TextBubbleStyled>);
            let msgElements = messages.map((m: Message, n: number) => {
              if (messages.length === (n + 1) || messages[n].side !== messages[n + 1].side)
                return (<MessageBlock key={m.id!} message={m} isLast={true} />)
              return (<MessageBlock key={m.id!} message={m} isLast={false} />)
            });
            return [...arr, ...msgElements];
          })
          }
        </MessagesScrolledContainerStyled>
      </MessageWrapperStyled>
    </MessageBaseContainerStyled>)
})

// interface MessageWrapperProps {
//     ref: RefObject<HTMLDivElement>
// }

let MessagesScrolledContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  row-gap: 16px;
  box-sizing: border-box;
`

let MessageBaseContainerStyled = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-end;
  margin: auto;
  align-items: center;
`

let MessageWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  //flex: 1;
  width: 100%;
  max-width: 800px;
  row-gap: 16px;
  padding: 0 20px;
  overflow-y: auto;
  box-sizing: border-box;
  
  scrollbar-width: thin;
  scrollbar-color: #249de4 #f5f5f5;

  //justify-content: flex-end;
  margin: 12px;
  margin-top: 0;

  @media (max-width: 800px) {
    gap: 12px;
  }
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    //box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    //-moz-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    //-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #979797;
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    //-moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
`

export default MessageWrapper