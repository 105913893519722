import styled from "styled-components"
import { Message, MessageSide } from "../models/Message"
import Avatar from "./Avatar"
import MessageBubble from "./MessageBubble"

let MessageBlock = (props: MessageBlockProps) => {
  return (
    <MessageBlockStyled side={props.message.side} className={`${(props.isLast ? "lastmsgblk" : "")}`}>
      {/* {props.message.side === MessageSide.User && <Avatar key={1} />} */}
      <MessageBubble key={2} temp={props.temp} message={props.message} />

      {props.isLast && <SvgStyled side={props.message.side} width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 12H9.35216C9.87414 12 10.0021 11.2735 9.51158 11.0951L8.57685 10.7552C3.92594 9.06398 0.613831 4.91065 0 0V12Z' fill='white' />
        <path d='M0 12H9.35216C9.87414 12 10.0021 11.2735 9.51158 11.0951L8.57685 10.7552C3.92594 9.06398 0.613831 4.91065 0 0V12Z' fill='#15A4D0' fillOpacity='0.1' />
      </SvgStyled>}
    </MessageBlockStyled>)
}

const SvgStyled = styled.svg<SvgStyledProps>`
  position: absolute;
  ${props => props.side === MessageSide.User ? "right" : "left"}: 100%;
  bottom: 0;
  display: flex;
  ${props => props.side === MessageSide.User ? "transform: rotateY(180deg);" : ""}
  ${props => props.side === MessageSide.User && `
  left: -9px;
    > path {
       fill: white;
    }
  `}
`

interface SvgStyledProps {
  side: MessageSide
}

let MessageBlockStyled = styled.div<MessageBlockStyledProps>`
  display: flex;
  flex-direction: ${props => props.side === MessageSide.User ? 'row' : 'row-reverse'};
  align-self: ${props => props.side === MessageSide.User ? 'flex-start' : 'flex-end'};
  gap: 16px;
  position: relative;

  @media (max-width: 800px) {
    gap: 12px;
    margin-left: ${props => props.side === MessageSide.User ? '0px' : '16px'};
    margin-right: ${props => props.side === MessageSide.User ? '16px' : '0px'};
  }

  border-radius: 8px;
  background: linear-gradient(0deg, rgba(21, 164, 208, 0.1), rgba(21, 164, 208, 0.1)), rgb(255, 255, 255);
  
  ${props => props.side === MessageSide.User && `
    background: white;
  `}

  &:last-child, &.lastmsgblk{
    ${props => props.side === MessageSide.User && `
        border-bottom-left-radius: 0;
        background: white;
        svg {
          path {
            fill: white;
          }
        }
      `}
      ${props => props.side !== MessageSide.User && `
        border-bottom-right-radius: 0;
      `}
  }
`

interface MessageBlockStyledProps {
  side: MessageSide
}

interface MessageBlockProps {
  message: Message,
  temp?: boolean,
  isLast: boolean
}

export default MessageBlock