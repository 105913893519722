import { useState } from "react";
import styled from "styled-components";
import { ImageMeta } from "../models/ImageExpandContext";
import ImageCarousel from "./ImageCarousel";

const ImageExpand = (props: ImageExpandProps) => {
  const IMAGE_CAROUSEL_SWIPE_LENGTH = 50;

  let propsIndex = props.carouselImages.indexOf(props.carouselImages.find(x => x.url === props.src)!);

  const [currentIndex, setCurrentIndex] = useState(propsIndex);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const touchStartHandler = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  }

  const touchMoveHandler = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  const touchEndHandler = () => {
    if (touchStart - touchEnd > IMAGE_CAROUSEL_SWIPE_LENGTH) {
      nextImageClickHandler();
    }

    if (touchStart - touchEnd < -IMAGE_CAROUSEL_SWIPE_LENGTH) {
      prevImageClickHandler();
    }
  }

  const nextImageClickHandler = () => {
    if (currentIndex + 1 >= props.carouselImages.length)
      setCurrentIndex(0);
    else
      setCurrentIndex(currentIndex + 1);
  }

  const prevImageClickHandler = () => {
    if (currentIndex - 1 < 0)
      setCurrentIndex(props.carouselImages.length - 1);
    else
      setCurrentIndex(currentIndex - 1);
  }

  const onNavigationElementClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <ImageExpandStyled onTouchStart={touchStartHandler} onTouchMove={touchMoveHandler} onTouchEnd={touchEndHandler}>
      <ImageExpandBackdropStyled></ImageExpandBackdropStyled>
      <ImageExpandCrossButtonStyled onClick={props.onCloseClick} />
      {props.carouselImages.length > 1 &&
        <>
          <ImgageExpandNextAreaStyled onClick={nextImageClickHandler}><ImageExpandNextButtonStyled /></ImgageExpandNextAreaStyled>
          <ImageExpandPrevAreaStyled onClick={prevImageClickHandler}><ImageExpandNextButtonStyled /></ImageExpandPrevAreaStyled>
        </>
      }
      <ImageCarousel currentIndex={currentIndex} carouselImages={props.carouselImages} onCloseClick={props.onCloseClick} onNavigationElementClick={onNavigationElementClick} />
    </ImageExpandStyled>)
}

export default ImageExpand

interface ImageExpandProps {
  src: string,
  carouselImages: ImageMeta[],
  onCloseClick: () => void
}

const ImgageExpandNextAreaStyled = styled.div`
  position:absolute;
  right: 0;
  top: 0;
  width: 4rem;
  height: 100%;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  
  transition: transform 0.1s ease-in-out;
  
  &:hover{
    transform: scale(1.15);  
  }

  @media (max-width: 800px) {
    display: none;
  }
  `
const ImageExpandPrevAreaStyled = styled.div`
  position:absolute;
  left: 0;
  top: 0;
  width: 4rem;
  height: 100%;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  
  transition: transform 0.1s ease-in-out;
  transform: scaleX(-1);
  
  &:hover{
    transform: scaleX(-1) scale(1.15);  
  }

  @media (max-width: 800px) {
    display: none;
  }
  `

const ImageExpandNextButtonStyled = styled.div`
  width: 2.5rem;
  height: 4rem;
  position: relative;
  z-index: 12;
  
  &:before, &:after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 1.25rem;
    width: 0.125rem;
    background-color: var(--element-color);
  }
  &:before {
    transform: rotate(45deg);
    top: 0.825rem;
  }
  &:after {
    transform: rotate(-45deg);
  }
  `

const ImageExpandCrossButtonStyled = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  width: 4rem;
  height: 4rem;
  z-index: 13;
  transition: transform 0.1s ease-in-out;
  
  &:hover{
    transform: scale(1.15);  
  }
  
  &:before, &:after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 1.25rem;
    width: 0.125rem;
    background-color: var(--element-color);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  `

const ImageExpandStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index:10;
  overflow: hidden;

  --element-color: #AAAAAB;
  `

const ImageExpandBackdropStyled = styled.div`
  background-color: #2C2C2E;
  opacity: 0.8; 
  width: 100%;
  height: 100%;
  position: absolute;
  `