import { Component, PropsWithChildren } from "react";
import ImageExpand from "../components/ImageExpand";
import ImageExpandContext, { ImageMeta } from "../models/ImageExpandContext";

class ImageExpandProvider extends Component<PropsWithChildren<any>, ImageExpandProviderState> {

  constructor(props: any) {
    super(props);
    this.state = {
      selectedSrc: "",
      carouselImages: [],
      // isFullscreenMode: false
    };
  }

  exitFullscreenHandler = (e: any) => {
    if (document.fullscreenElement === null) {
      this.setState({
        selectedSrc: "",
        carouselImages: [],
        // isFullscreenMode: false
      })
    }
  }

  // componentDidMount(): void {
    
  //   window.addEventListener('fullscreenchange', this.exitFullscreenHandler, false);
  //   window.addEventListener('mozfullscreenchange', this.exitFullscreenHandler, false);
  //   window.addEventListener('MSFullscreenChange', this.exitFullscreenHandler, false);
  //   window.addEventListener('webkitfullscreenchange', this.exitFullscreenHandler, false);
  // }

  // componentWillUnmount(): void {
  //   window.removeEventListener('fullscreenchange', this.exitFullscreenHandler);
  //   window.removeEventListener('mozfullscreenchange', this.exitFullscreenHandler);
  //   window.removeEventListener('MSFullscreenChange', this.exitFullscreenHandler);
  //   window.removeEventListener('webkitfullscreenchange', this.exitFullscreenHandler);
  // }

  // componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ImageExpandProviderState>, snapshot?: any): void {
  //   if (prevState.isFullscreenMode === false && this.state.isFullscreenMode === true) {
  //     let elem = document.documentElement;
  //     if (elem.requestFullscreen) {
  //       elem.requestFullscreen({ navigationUI: "hide" });
  //     }
  //   }
  // }

  showImageExpand = (selectedSrc: string, carouselImages: ImageMeta[]) => {
    this.setState({
      selectedSrc: selectedSrc,
      carouselImages: carouselImages,
      // isFullscreenMode: true
    });
  }

  onCloseClick = () => {
    this.setState({
      selectedSrc: "",
      carouselImages: [],
      // isFullscreenMode: false
    })
    if (document.fullscreenElement !== null)
      document.exitFullscreen();
  }

  render() {
    return (
      <ImageExpandContext.Provider value={{
        selectedSrc: this.state.selectedSrc,
        carouselImages: this.state.carouselImages,
        showImageExpand: this.showImageExpand
      }}>
        {this.props.children}
        {this.state.selectedSrc && <ImageExpand carouselImages={this.state.carouselImages} src={this.state.selectedSrc}
          onCloseClick={this.onCloseClick} />}
      </ImageExpandContext.Provider>
    )
  }
}

export default ImageExpandProvider

interface ImageExpandProviderState {
  selectedSrc: string
  carouselImages: ImageMeta[],
  // isFullscreenMode: boolean
}