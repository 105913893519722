import React from "react";

export class ImageExpandContextType {
    public selectedSrc: string = ""
    public carouselImages: ImageMeta[] = []

    showImageExpand: (selectedSrc: string, carouselImages: ImageMeta[]) => void = (selectedSrc, gallerySrcs) => { }
}

const ImageExpandContext = React.createContext<ImageExpandContextType>(new ImageExpandContextType());

export default ImageExpandContext;

export interface ImageMeta {
    url: string
}