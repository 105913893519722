import { MessagePartType, MessageSide } from './models/Message';
import Chat from './components/Chat';
import MessagesContextProvider from './providers/MessagesProvider';
import InterviewContextProvider from './providers/InterviewProvider';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import ImageExpandProvider from './providers/ImageExpandProvider';


function App() {

  if ("virtualKeyboard" in navigator) {
    let keyboardNavigator: KeyboardNavigatorType = navigator.virtualKeyboard as KeyboardNavigatorType;
    keyboardNavigator.overlaysContent = true;
    keyboardNavigator.ongeometrychange = () => {
      document.getElementsByTagName("html")[0].style.height = `calc(100% - ${keyboardNavigator.boundingRect.height}px)`;
    }
  }

  return (
    <AppStyled>
      <ImageExpandProvider>
        <MessagesContextProvider messages={[]}>
          <Chat />
        </MessagesContextProvider>
      </ImageExpandProvider>
    </AppStyled>
  );
}

interface KeyboardNavigatorType {
  overlaysContent: boolean,
  ongeometrychange: () => void,
  boundingRect: DOMRect
}

let AppStyled = styled.div`
text-align: center;
display: flex;
justify-content: center;
height: 100%;

font-family: 'Proxima Nova', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;

box-sizing: border-box;
position: relative;  
background: linear-gradient(224.13deg, #15A4D0 -0.15%, #DBF7FF -0.14%, #8FD4E9 97.82%);
border-width: 1px 1px 1px 0px;
border-style: solid;
border-color: #E9E9E9;
`

export default App;
